@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap');

*
{
    box-sizing: border-box;
}

body
{
    --bg-color: rgba( 255,255,255,.75 );
    font-family: Poppins, sans-serif;
    background-image: linear-gradient( 0deg, var( --bg-color ), var( --bg-color ) ), url( './img/bg.jpg' );
}

.pokedex-app
{
    padding: 1rem;
    border-radius: 1rem;
}

.pokedex-app > h1
{
    text-align: center;
    font-size: 3rem;
    margin: 1rem 0 4rem;
    color: #444;
    position: relative;
}

.pokedex-app > h1::after
{
    content: '';
    position: relative;
    display: block;
    width: 2em;
    height: 4px;
    background-color: #FB6C6C;
    top: 100%;
    left: 50%;
    margin-left: -1em;
}

.pokedex-app > h1 > i
{
    display: inline-block;
    height: .6em;
    width: .6em;
    background-image: url( './img/pokeball.svg' );
    background-repeat: no-repeat;
}

/*
    Pokemon type colors
*/
.type-normal { --color: #C2C2A1; }

.type-flying { --color: #BAB0D5; }

.type-ghost { --color: #735797; }

.type-dark { --color: #333; }

.type-steel { --color: #CCCCDE; }

.type-ground { --color: #B1736C; }

.type-poison { --color: #7C538C; }

.type-grass { --color: #48D0B0; }

.type-fire { --color: #FB6C6C; }

.type-electric { --color: #FFD86F; }

.type-fairy { --color: #f469a9; }

.type-bug { --color: #C3CE75; }

.type-fighting { --color: #d6b591; }

.type-water { --color: #609FB5; }

.type-psychic { --color: #9B7FA6; }

.type-ice { --color: #7FCCEC; }

.type-rock { --color: #a6aab6; }

.type-dragon { --color: #F9BE00; }

/*
    Background Pokeball decoration
*/
.bg-pokeball
{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10px;
    bottom: -10px;
    font-size: 10px;
    color: rgba( 255,255,255,.2 );
}

.bg-pokeball::before, .bg-pokeball::after
{
    display: block;
    border-radius: 100%;
}

.bg-pokeball::before
{
    content: '';
    position: relative;
    width: 10em;
    padding-bottom: 10em;
    border: 4em solid currentColor;
    clip-path: polygon( 0 0, 0 40%, 50% 40%, 50% 60%, 0 60%, 0 100%, 100% 100%, 100% 60%, 50% 60%, 50% 40%, 100% 40%, 100% 0 );
}

.bg-pokeball::after
{
    content: '';
    position: absolute;
    width: 5em;
    padding-bottom: 5em;
    background-color: currentColor;
}

/*
    Animations
*/
@keyframes spin
{
    from { transform: rotate( 0 ); }
    to { transform: rotate( 360deg ); }
}

@keyframes pull-up-center
{
    from { transform: translateY( 50% ) translateX( -50% ); opacity: 0; }
    to { transform: translateY( 0 ) translateX( -50% ); opacity: 1; }
}

@keyframes pull-down-center
{
    from { transform: translateY( 0 ) translateX( -50% ); opacity: 1; }
    to { transform: translateY( 50% ) translateX( -50% ); opacity: 0; }
}

@keyframes pull-up
{
    from { transform: translateY( 10% ); opacity: 0; }
    to { transform: translateY( 0 ); opacity: 1; }
}

@keyframes fade-in
{
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out
{
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fill
{
    from { width: 0; }
}