.details
{
    background-color: #FFF;
    color: #333;
    box-shadow: 0 0 10px 0 rgba( 0,0,0,.4 );
    border-radius: 3rem;
    padding: 2rem;
    position: relative;
    z-index: 2;
    animation: 0.3s pull-up both ease-out .2s;
}

.details .pokemon-image
{
    position: absolute;
    bottom: 100%;
    margin-bottom: -2rem;
    left: 50%;
    transform: translateX( -50% );
    max-width: 30%;
    height: auto;
    max-height: 20vh;
    filter: drop-shadow( 0 0 10px rgba( 0,0,0,.2 ) );
    animation: 0.3s fade-in ease-out .5s both;
}

.details .tabs-switch-container
{
    margin-bottom: .5rem;
    border-bottom: 2px solid rgba( 0,0,0,.05 );
}

.details .tabs-switch-container .tab-switch
{
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    font-family: inherit;
    padding: 1rem 1rem;
    position: relative;
    color: rgba( 0,0,0,.2 );
    outline: none;
    transition: .3s all;
}

.details .tabs-switch-container .tab-switch.active,
.details .tabs-switch-container .tab-switch:hover
{
    color: inherit;
}

.details .tabs-switch-container .tab-switch.active::after
{
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    top: 100%;
    margin-top: -.5px;
    left: 0;
    position: absolute;
    background-color: #6C79DB;
}

.details .tab
{
    overflow-y: auto;
}

.details .tab table
{
    width: 100%;
}

.details .tab table td
{
    padding: .5rem;
    text-transform: capitalize;
}

.details .tab table td:first-child
{
    width: 20%;
}

.details .tab table td:nth-child( 2 )
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}

.details .tab table td:nth-child( 2 ) .range-view
{
    width: 90%;
}

.details .tab-evolution .loader
{
    padding: 1em;
}

.details .tab-evolution .evolution-container
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}

.details .tab-evolution .evolution-container:last-child
{
    margin-bottom: 0;
}

.details .tab-evolution .evolve-container
{
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: bold;
}

.details .tab-evolution .evolve-container .image-container
{
    position: relative;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.details .tab-evolution .evolve-container .bg-pokeball
{
    color: rgba( 0,0,0,.05 );
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translateX( -50% ) translateY( -50% );
    font-size: .7vmin;
    z-index: 1;
}

.details .tab-evolution .evolve-container img
{
    max-width: 80%;
    max-height: 10vh;
    height: auto;
    position: relative;
    z-index: 2;
}

.details .tab-evolution .evolution-container .trigger-container
{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}

.details .tab-evolution .evolution-container .trigger-container .arrow
{
    --color: #EEE;
    --width: 3px;
    display: block;
    height: var( --width );
    width: 2.5em;
    background-color: var( --color );
    position: relative;
    margin-bottom: 10px;
}

.details .tab-evolution .evolution-container .trigger-container .arrow::after
{
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 5px;
    border: var( --width ) solid var( --color );
    border-left-color: transparent;
    border-bottom-color: transparent;
    right: 0;
    top: 0;
    margin-top: calc( var( --width ) * -1.4 );
    transform: rotate( 45deg );
}

@media screen and ( max-width: 500px )
{
    .details .tabs-switch-container .tab-switch
    {
        padding: .5rem;
        font-size: .8rem;
    }

    .details .tab
    {
        font-size: .8rem;
    }
}