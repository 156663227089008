.overlay
{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .3s all;
    background-color: rgba( 0,0,0,.5 );
}

.overlay.hidden
{
    opacity: 0;
    pointer-events: none;
}