@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap);
.card-container
{
    padding: 1rem;
    height: 100%;
    cursor: pointer;
}

.card
{
    background-color: var( --color );
    color: #FFF;
    box-shadow: 0 0 20px 0 var( --color );
    border-radius: 3rem;
    overflow: hidden;
    height: 100%;
    padding: 3rem 1rem 3rem 3rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    will-change: box-shadow, transform;
    transition: .3s all;
}

.card .card-title
{
    align-self: flex-start;
}

.card .card-title h2
{
    text-transform: capitalize;
    margin: 0;
    font-size: 2rem;
}

.card .card-title .pokemon-types
{
    margin-top: 1rem;
}

.card .card-title span.type
{
    border-radius: 100rem;
    display: table;
    background-color: rgba( 255,255,255,.2 );
    padding: .3rem .7rem;
    text-align: center;
    margin-bottom: .5rem;
    text-transform: capitalize;
}

.card .pokemon-image
{
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 50%;
}

.card img
{
    max-width: 100%;
    max-height: 100%;
    -webkit-filter: drop-shadow( 0 0 5px rgba( 0,0,0,.2 ) );
            filter: drop-shadow( 0 0 5px rgba( 0,0,0,.2 ) );
}

.pokemon-id
{
    position: absolute;
    top: 20px;
    right: 40px;
    font-size: 2.5rem;
    color: rgba( 255,255,255,.2 );
}

@media screen and ( max-width: 500px )
{
    :not( .details-view ) .card
    {
        align-items: flex-start;
        justify-content: space-between;
        height: 40vh;
        padding: 2rem;
    }

    .card .card-title h2
    {
        font-size: 1.2rem;
    }

    .pokemon-id
    {
        font-size: 2rem;
        top: 20px;
        right: 20px;
    }
}

@media screen and ( min-width: 1024px )
{
    .card:hover
    {
        transform: translateY( -5px );
        box-shadow: 0 0 20px 5px var( --color );
    }
}
.back-button
{
    --color: #FFF;
    --width: 3px;
    cursor: pointer;
    position: absolute;
    z-index: 999;
    left: 20px;
    top: 40px;
    height: var( --width );
    width: 20px;
    background-color: var( --color );
}

.back-button::before
{
    content: '';
    display: block;
    position: absolute;
    border: var( --width ) solid var( --color );
    border-top-color: transparent;
    border-right-color: transparent;
    height: 10px;
    width: 10px;
    left: 0;
    top: calc( var( --width ) * -2 );
    transform: rotate( 45deg );
}
.range-view
{
    width: 100%;
    background-color: rgba( 0,0,0,.1 );
    border-radius: 100rem;
    overflow: hidden;
    height: 5px;
    margin: 0 10px;
    position: relative;
}

.range-view::after
{
    content: '';
    display: block;
    height: 100%;
    width: var( --percent );
    position: absolute;
    left: 0;
    top: 0;
    border-radius: inherit;
    background-color: #000;
    animation: fill .3s backwards;
}

.range-view.range-view-positive::after
{
    background-color: #5BC686;
}

.range-view.range-view-negative::after
{
    background-color: #FB7171;
}
.loader
{
    font-size: 1.2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20vh 0;
    flex-direction: column;
}

.loader::before
{
    content: '';
    display: block;
    height: 2em;
    width: 2em;
    border: .6em solid #FB6C6C;
    border-radius: 100%;
    margin-bottom: 10px;
    -webkit-clip-path: polygon( 0 0, 0 40%, 50% 40%, 50% 60%, 0 60%, 0 100%, 100% 100%, 100% 60%, 50% 60%, 50% 40%, 100% 40%, 100% 0 );
            clip-path: polygon( 0 0, 0 40%, 50% 40%, 50% 60%, 0 60%, 0 100%, 100% 100%, 100% 60%, 50% 60%, 50% 40%, 100% 40%, 100% 0 );
    animation: spin .5s infinite ease-in-out;
}

.loader::after
{
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -1.6em;
    height: 1.2em;
    width: 1.2em;
    background-color: #CCC;
    border-radius: 100%;
}
.details
{
    background-color: #FFF;
    color: #333;
    box-shadow: 0 0 10px 0 rgba( 0,0,0,.4 );
    border-radius: 3rem;
    padding: 2rem;
    position: relative;
    z-index: 2;
    animation: 0.3s pull-up both ease-out .2s;
}

.details .pokemon-image
{
    position: absolute;
    bottom: 100%;
    margin-bottom: -2rem;
    left: 50%;
    transform: translateX( -50% );
    max-width: 30%;
    height: auto;
    max-height: 20vh;
    -webkit-filter: drop-shadow( 0 0 10px rgba( 0,0,0,.2 ) );
            filter: drop-shadow( 0 0 10px rgba( 0,0,0,.2 ) );
    animation: 0.3s fade-in ease-out .5s both;
}

.details .tabs-switch-container
{
    margin-bottom: .5rem;
    border-bottom: 2px solid rgba( 0,0,0,.05 );
}

.details .tabs-switch-container .tab-switch
{
    background: transparent;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    font-family: inherit;
    padding: 1rem 1rem;
    position: relative;
    color: rgba( 0,0,0,.2 );
    outline: none;
    transition: .3s all;
}

.details .tabs-switch-container .tab-switch.active,
.details .tabs-switch-container .tab-switch:hover
{
    color: inherit;
}

.details .tabs-switch-container .tab-switch.active::after
{
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    top: 100%;
    margin-top: -.5px;
    left: 0;
    position: absolute;
    background-color: #6C79DB;
}

.details .tab
{
    overflow-y: auto;
}

.details .tab table
{
    width: 100%;
}

.details .tab table td
{
    padding: .5rem;
    text-transform: capitalize;
}

.details .tab table td:first-child
{
    width: 20%;
}

.details .tab table td:nth-child( 2 )
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}

.details .tab table td:nth-child( 2 ) .range-view
{
    width: 90%;
}

.details .tab-evolution .loader
{
    padding: 1em;
}

.details .tab-evolution .evolution-container
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}

.details .tab-evolution .evolution-container:last-child
{
    margin-bottom: 0;
}

.details .tab-evolution .evolve-container
{
    display: flex;
    flex-basis: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: bold;
}

.details .tab-evolution .evolve-container .image-container
{
    position: relative;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.details .tab-evolution .evolve-container .bg-pokeball
{
    color: rgba( 0,0,0,.05 );
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translateX( -50% ) translateY( -50% );
    font-size: .7vmin;
    z-index: 1;
}

.details .tab-evolution .evolve-container img
{
    max-width: 80%;
    max-height: 10vh;
    height: auto;
    position: relative;
    z-index: 2;
}

.details .tab-evolution .evolution-container .trigger-container
{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}

.details .tab-evolution .evolution-container .trigger-container .arrow
{
    --color: #EEE;
    --width: 3px;
    display: block;
    height: var( --width );
    width: 2.5em;
    background-color: var( --color );
    position: relative;
    margin-bottom: 10px;
}

.details .tab-evolution .evolution-container .trigger-container .arrow::after
{
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 5px;
    border: var( --width ) solid var( --color );
    border-left-color: transparent;
    border-bottom-color: transparent;
    right: 0;
    top: 0;
    margin-top: calc( var( --width ) * -1.4 );
    transform: rotate( 45deg );
}

@media screen and ( max-width: 500px )
{
    .details .tabs-switch-container .tab-switch
    {
        padding: .5rem;
        font-size: .8rem;
    }

    .details .tab
    {
        font-size: .8rem;
    }
}
.overlay
{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .3s all;
    background-color: rgba( 0,0,0,.5 );
}

.overlay.hidden
{
    opacity: 0;
    pointer-events: none;
}
.details-view-container
{
    max-height: 100vh;
    max-width: 800px;
    width: 95vw;
    bottom: 0;
    left: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    z-index: 99999;
    background-color: #FFF;
    border-radius: 3rem;
    animation: .3s pull-up-center both ease-out;
}

.details-view-container.hidden
{
    animation: .3s pull-down-center both ease-out;
}

.details-view-container .card-container
{
    cursor: auto;
    margin: 0;
    padding: 0;
    height: auto;
    transform: translateY( 40px );
    margin-top: -40px;
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.details-view-container .card
{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 25vmin;
    padding-top: 5vmin;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba( 0,0,0,.3 );
}

.details-view-container .card .card-title span.type
{
    display: inline-block;
    margin-right: 5px;
}

.details-view-container .card .pokemon-image
{
    display: none;
}


@media screen and ( max-width: 800px )
{
    .details-view-container .card .card-title
    {
        align-self: center;
    }
}
.navigation-container
{
    text-align: center;
    margin-bottom: 4rem;
}

.navigation-title
{
    vertical-align: middle;
    font-weight: bold;
    margin: 0 auto 1rem;
    font-size: 1.5rem;
    color: #6C79DB;
    text-shadow: 0 0 15px rgba( 0,0,0,.3 );
}

.navigation-container .links-container
{
    display: table;
    margin: auto;
    background-color: #FFF;
    box-shadow: 0 0 15px 0 rgba( 0,0,0,.2 );
}

.navigation-link
{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #333;
    font-size: 1.3rem;
    padding: .5rem 1rem;
    font-weight: bold;
    text-decoration: none;
    overflow: hidden;
    transition: .3s all;
}

.navigation-link:hover,
.navigation-link:focus,
.navigation-link.active
{
    outline: none;
    background-color: #EEE;
}

.navigation-link::after
{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    background-color: #6C79DB;
    opacity: 0;
    border-radius: 100px;
    transform: scaleX( 0 );
    transform-origin: center;
    transition: .3s all;
}

.navigation-link.navigation-link.active::after
{
    opacity: 1;
    transform: scaleX( 1 );
}
.pokedex-view
{
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat( 1, 1fr );
}

@media screen and ( min-width: 800px )
{
    .pokedex-view
    {
        grid-template-columns: repeat( 2, 1fr );
    }
}

@media screen and ( min-width: 1200px )
{
    .pokedex-view
    {
        grid-template-columns: repeat( 3, 1fr );
    }
}

@media screen and ( min-width: 1920px )
{
    .pokedex-view
    {
        grid-template-columns: repeat( 4, 1fr );
    }
}
*
{
    box-sizing: border-box;
}

body
{
    --bg-color: rgba( 255,255,255,.75 );
    font-family: Poppins, sans-serif;
    background-image: linear-gradient( 0deg, var( --bg-color ), var( --bg-color ) ), url( /static/media/bg.65cf6936.jpg );
}

.pokedex-app
{
    padding: 1rem;
    border-radius: 1rem;
}

.pokedex-app > h1
{
    text-align: center;
    font-size: 3rem;
    margin: 1rem 0 4rem;
    color: #444;
    position: relative;
}

.pokedex-app > h1::after
{
    content: '';
    position: relative;
    display: block;
    width: 2em;
    height: 4px;
    background-color: #FB6C6C;
    top: 100%;
    left: 50%;
    margin-left: -1em;
}

.pokedex-app > h1 > i
{
    display: inline-block;
    height: .6em;
    width: .6em;
    background-image: url( /static/media/pokeball.b680bb83.svg );
    background-repeat: no-repeat;
}

/*
    Pokemon type colors
*/
.type-normal { --color: #C2C2A1; }

.type-flying { --color: #BAB0D5; }

.type-ghost { --color: #735797; }

.type-dark { --color: #333; }

.type-steel { --color: #CCCCDE; }

.type-ground { --color: #B1736C; }

.type-poison { --color: #7C538C; }

.type-grass { --color: #48D0B0; }

.type-fire { --color: #FB6C6C; }

.type-electric { --color: #FFD86F; }

.type-fairy { --color: #f469a9; }

.type-bug { --color: #C3CE75; }

.type-fighting { --color: #d6b591; }

.type-water { --color: #609FB5; }

.type-psychic { --color: #9B7FA6; }

.type-ice { --color: #7FCCEC; }

.type-rock { --color: #a6aab6; }

.type-dragon { --color: #F9BE00; }

/*
    Background Pokeball decoration
*/
.bg-pokeball
{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10px;
    bottom: -10px;
    font-size: 10px;
    color: rgba( 255,255,255,.2 );
}

.bg-pokeball::before, .bg-pokeball::after
{
    display: block;
    border-radius: 100%;
}

.bg-pokeball::before
{
    content: '';
    position: relative;
    width: 10em;
    padding-bottom: 10em;
    border: 4em solid currentColor;
    -webkit-clip-path: polygon( 0 0, 0 40%, 50% 40%, 50% 60%, 0 60%, 0 100%, 100% 100%, 100% 60%, 50% 60%, 50% 40%, 100% 40%, 100% 0 );
            clip-path: polygon( 0 0, 0 40%, 50% 40%, 50% 60%, 0 60%, 0 100%, 100% 100%, 100% 60%, 50% 60%, 50% 40%, 100% 40%, 100% 0 );
}

.bg-pokeball::after
{
    content: '';
    position: absolute;
    width: 5em;
    padding-bottom: 5em;
    background-color: currentColor;
}

/*
    Animations
*/
@keyframes spin
{
    from { transform: rotate( 0 ); }
    to { transform: rotate( 360deg ); }
}

@keyframes pull-up-center
{
    from { transform: translateY( 50% ) translateX( -50% ); opacity: 0; }
    to { transform: translateY( 0 ) translateX( -50% ); opacity: 1; }
}

@keyframes pull-down-center
{
    from { transform: translateY( 0 ) translateX( -50% ); opacity: 1; }
    to { transform: translateY( 50% ) translateX( -50% ); opacity: 0; }
}

@keyframes pull-up
{
    from { transform: translateY( 10% ); opacity: 0; }
    to { transform: translateY( 0 ); opacity: 1; }
}

@keyframes fade-in
{
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fade-out
{
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fill
{
    from { width: 0; }
}
