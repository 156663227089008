.navigation-container
{
    text-align: center;
    margin-bottom: 4rem;
}

.navigation-title
{
    vertical-align: middle;
    font-weight: bold;
    margin: 0 auto 1rem;
    font-size: 1.5rem;
    color: #6C79DB;
    text-shadow: 0 0 15px rgba( 0,0,0,.3 );
}

.navigation-container .links-container
{
    display: table;
    margin: auto;
    background-color: #FFF;
    box-shadow: 0 0 15px 0 rgba( 0,0,0,.2 );
}

.navigation-link
{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #333;
    font-size: 1.3rem;
    padding: .5rem 1rem;
    font-weight: bold;
    text-decoration: none;
    overflow: hidden;
    transition: .3s all;
}

.navigation-link:hover,
.navigation-link:focus,
.navigation-link.active
{
    outline: none;
    background-color: #EEE;
}

.navigation-link::after
{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    bottom: 0;
    background-color: #6C79DB;
    opacity: 0;
    border-radius: 100px;
    transform: scaleX( 0 );
    transform-origin: center;
    transition: .3s all;
}

.navigation-link.navigation-link.active::after
{
    opacity: 1;
    transform: scaleX( 1 );
}