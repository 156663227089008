.pokedex-view
{
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat( 1, 1fr );
}

@media screen and ( min-width: 800px )
{
    .pokedex-view
    {
        grid-template-columns: repeat( 2, 1fr );
    }
}

@media screen and ( min-width: 1200px )
{
    .pokedex-view
    {
        grid-template-columns: repeat( 3, 1fr );
    }
}

@media screen and ( min-width: 1920px )
{
    .pokedex-view
    {
        grid-template-columns: repeat( 4, 1fr );
    }
}