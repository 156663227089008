.back-button
{
    --color: #FFF;
    --width: 3px;
    cursor: pointer;
    position: absolute;
    z-index: 999;
    left: 20px;
    top: 40px;
    height: var( --width );
    width: 20px;
    background-color: var( --color );
}

.back-button::before
{
    content: '';
    display: block;
    position: absolute;
    border: var( --width ) solid var( --color );
    border-top-color: transparent;
    border-right-color: transparent;
    height: 10px;
    width: 10px;
    left: 0;
    top: calc( var( --width ) * -2 );
    transform: rotate( 45deg );
}